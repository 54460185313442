<template>
  <FormCard
    :title="agencyname ? agencyname : 'Nova Agência'"
    :status="agencystatus"
    @save="validate"
    @cancel="$router.push({ name: 'agencies' })"
  >
    <v-form
      class="px-5"
      ref="form"
    >
      <input
        type="password"
        name="password"
        label="senha"
        class="d-none"
        autocomplete="new-password"
      />
      <section
        v-for="(section, idx) in sections"
        :key="idx"
      >
        <SectionTitle :title="section.title" />
        <component
          :is="section.component"
          :agency="agency"
        />
      </section>
    </v-form>
    <v-dialog
      v-model="showWarningModal"
      max-width="450"
      persistent
    >
      <v-card class="pa-5">
        <v-card-title class="headline text-center">
          <v-icon
            color="warning"
            class="mr-2"
            >fas fa-exclamation-triangle</v-icon
          >
          Atenção
        </v-card-title>
        <v-card-text class="text-center">
          <div
            v-html="warningMessage"
            class="text-left"
          ></div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            @click="showWarningModal = false"
          >
            <v-icon left>fas fa-check</v-icon> OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </FormCard>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    FormCard: () => import('@/components/FormCard'),
    BasicData: () => import('./form-sections/BasicData'),
    Documents: () => import('./form-sections/Documents'),
    Location: () => import('./form-sections/Location'),
  },
  props: {
    agency: {
      type: Object,
      required: true,
    },
    type: String,
  },
  data: () => ({
    sections: [
      { title: 'Dados Básicos', component: 'basic-data' },
      { title: 'Documentos', component: 'documents' },
      { title: 'Endereço', component: 'location' },
    ],
    profile: null,
    warningMessage: '',
    showWarningModal: false,
  }),
  methods: {
    validate() {
      // Lista de campos obrigatórios ausentes
      const missingFields = []

      if (!this.agency.company_name) {
        missingFields.push('Razão Social')
      }
      if (!this.agency.name) {
        missingFields.push('Nome')
      }
      if (!this.agency.email) {
        missingFields.push('Email')
      }
      if (!this.agency.seller_id) {
        missingFields.push('Vendedor')
      }

      if (missingFields.length > 0) {
        this.warningMessage = `
        <p>Os seguintes campos são <strong>obrigatórios</strong> e precisam ser preenchidos:</p>
        <ul>
          ${missingFields
            .map((field) => `<li><strong>${field}</strong></li>`)
            .join('')}
        </ul>
      `
        this.showWarningModal = true
        return // Impede o envio se houver campos faltando
      }

      // Se todos os campos obrigatórios foram preenchidos, emite o evento de save
      this.$emit('save')
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
}
</script>
